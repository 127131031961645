import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'
import { SIX, FINIX, BNB, USDT, BUSD, BTCB, ETH, XRP, ADA, getLpNetwork, getSingleLpNetwork, VELO2 } from './tokens'
import { ColorStyles } from 'uikitV2/colors'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'FINIX',
    lpAddresses: getSingleLpNetwork(FINIX),
    tokenSymbol: 'FINIX',
    tokenAddresses: FINIX,
    quoteTokenSymbol: QuoteToken.FINIX,
    quoteTokenAdresses: contracts.finix,
    tag: 'special',
  },
  {
    pid: 1,
    lpSymbol: 'FINIX-SIX LP',
    lpAddresses: getLpNetwork(FINIX, SIX),
    tokenSymbol: 'SIX',
    tokenAddresses: SIX,
    quoteTokenSymbol: QuoteToken.FINIX,
    quoteTokenAdresses: contracts.finix,
  },
  {
    pid: 2,
    lpSymbol: 'FINIX-BUSD LP',
    lpAddresses: getLpNetwork(FINIX, BUSD),
    tokenSymbol: 'BUSD',
    tokenAddresses: BUSD,
    quoteTokenSymbol: QuoteToken.FINIX,
    quoteTokenAdresses: contracts.finix,
  },
  {
    pid: 31,
    lpSymbol: 'FINIX-USDT LP',
    lpAddresses: getLpNetwork(FINIX, USDT),
    tokenSymbol: 'USDT',
    tokenAddresses: USDT,
    quoteTokenSymbol: QuoteToken.FINIX,
    quoteTokenAdresses: contracts.finix,
  },
  {
    pid: 3,
    lpSymbol: 'FINIX-BNB LP',
    lpAddresses: getLpNetwork(FINIX, BNB),
    tokenSymbol: 'BNB',
    tokenAddresses: BNB,
    quoteTokenSymbol: QuoteToken.FINIX,
    quoteTokenAdresses: contracts.finix,
  },
  {
    pid: 4,
    lpSymbol: 'SIX-BUSD LP',
    lpAddresses: getLpNetwork(SIX, BUSD),
    tokenSymbol: 'SIX',
    tokenAddresses: SIX,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 32,
    lpSymbol: 'SIX-USDT LP',
    lpAddresses: getLpNetwork(SIX, USDT),
    tokenSymbol: 'SIX',
    tokenAddresses: SIX,
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 5,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: getLpNetwork(USDT, BUSD),
    tokenSymbol: 'USDT',
    tokenAddresses: USDT,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 6,
    lpSymbol: 'SIX-BNB LP',
    lpAddresses: getLpNetwork(SIX, BNB),
    tokenSymbol: 'SIX',
    tokenAddresses: SIX,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 7,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: getLpNetwork(BNB, BUSD),
    tokenSymbol: 'BNB',
    tokenAddresses: BNB,
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 8,
    lpSymbol: 'BNB-BTCB LP',
    lpAddresses: getLpNetwork(BNB, BTCB),
    tokenSymbol: 'BTCB',
    tokenAddresses: BTCB,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 9,
    lpSymbol: 'ETH-BNB LP',
    lpAddresses: getLpNetwork(ETH, BNB),
    tokenSymbol: 'ETH',
    tokenAddresses: ETH,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 10,
    lpSymbol: 'XRP-BNB LP',
    lpAddresses: getLpNetwork(XRP, BNB),
    tokenSymbol: 'XRP',
    tokenAddresses: XRP,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 11,
    lpSymbol: 'ADA-BNB LP',
    lpAddresses: getLpNetwork(ADA, BNB),
    tokenSymbol: 'ADA',
    tokenAddresses: ADA,
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 12,
    lpSymbol: 'ETH-BTCB LP',
    lpAddresses: getLpNetwork(ETH, BTCB),
    tokenSymbol: 'BTCB',
    tokenAddresses: BTCB,
    quoteTokenSymbol: QuoteToken.ETH,
    quoteTokenAdresses: contracts.eth,
  },
  {
    pid: 25,
    lpSymbol: 'SIX LP',
    lpAddresses: getSingleLpNetwork(SIX),
    tokenSymbol: 'SIX',
    tokenAddresses: SIX,
    quoteTokenSymbol: QuoteToken.FINIX,
    quoteTokenAdresses: contracts.finix,
  },
  // {
  //   pid: 91,
  //   lpSymbol: 'BUSD-VELO LP',
  //   lpAddresses: getLpNetwork(BUSD, VELO2),
  //   tokenSymbol: 'VELO',
  //   tokenAddresses: VELO2,
  //   quoteTokenSymbol: QuoteToken.VELO,
  //   quoteTokenAdresses: contracts.velo2,
  // },
  {
    pid: 28,
    lpSymbol: 'BNB-VELO LP',
    lpAddresses: getLpNetwork(BNB, VELO2),
    tokenSymbol: 'VELO',
    tokenAddresses: VELO2,
    quoteTokenSymbol: QuoteToken.VELO,
    quoteTokenAdresses: contracts.velo2,
  },
  {
    pid: 29,
    lpSymbol: 'FINIX-VELO LP',
    lpAddresses: getLpNetwork(FINIX, VELO2),
    tokenSymbol: 'VELO',
    tokenAddresses: VELO2,
    quoteTokenSymbol: QuoteToken.VELO,
    quoteTokenAdresses: contracts.velo2,
  },
]

export const TAG_COLORS = {
  hot: ColorStyles.RED,
  special: ColorStyles.YELLOW,
}

export default farms
